import { APP_INITIALIZER, inject, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportsComponent } from './reports.component';
import { ReportsRoutingModule } from './reports-routing.module';
import { EffectsModule } from '@ngrx/effects';
import { ReportModuleEffects } from '@app/areas/reports/store';
import { ReportsApiService } from '@app/areas/reports/services';
import { ConfigurationService } from '@ep/shared';
import { getEnvironment } from '@shared/utils';
import assetCacheBusting from '../../../../asset-cache-busting.json';
import { Store } from '@ngrx/store';
import { OrderActions, ReportActions } from '@app/resources/ngrx/actions';
import { selectJwt } from '@app/resources/ngrx/selectors/credentials.selector';
import { filter } from 'rxjs/operators';
import { combineLatest, take, tap } from 'rxjs';
import { BatchService, ReportService } from '@app/resources/services';

@NgModule({
  imports: [CommonModule, ReportsRoutingModule, ReportsComponent, EffectsModule.forFeature([ReportModuleEffects])],
  providers: [
    ReportService,
    ReportsApiService,
    ConfigurationService,
    BatchService,
    {
      provide: APP_INITIALIZER,
      useFactory: (configurationService: ConfigurationService) => () =>
        configurationService.load(
          getEnvironment(),
          assetCacheBusting[getEnvironment() as keyof typeof assetCacheBusting]
        ),
      deps: [ConfigurationService],
      multi: true,
    },
  ],
})
export class ReportsModule {
  private readonly store = inject(Store);
  private readonly configurationService = inject(ConfigurationService);

  constructor() {
    combineLatest([
      this.store.select(selectJwt).pipe(
        filter((jwt) => !!jwt),
        take(1)
      ),
      this.configurationService.hasConfigurationLoaded$.pipe(
        filter((config) => !!config),
        take(1)
      ),
    ])
      .pipe(
        take(1),
        tap(([jwt, config]) => {
          this.store.dispatch(ReportActions.loadReports());
        })
      )
      .subscribe();
  }
}
