import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { I18nModule } from '@app/i18n';
import { AuthModule } from '@app/auth';
import { ShellComponent } from './shell.component';
import { HeaderComponent } from './header/header.component';
import { SplitButtonModule } from 'primeng/splitbutton';
import { DropdownModule } from 'primeng/dropdown';
import { SharedModule } from '@app/@shared';
import { TooltipModule } from 'primeng/tooltip';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { IframeManagerModule, LoadingSpinnerModule } from '@ep/shared';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AccordionModule } from 'primeng/accordion';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    NgbModule,
    AuthModule,
    I18nModule,
    RouterModule,
    SplitButtonModule,
    DropdownModule,
    SharedModule,
    TooltipModule,
    TieredMenuModule,
    IframeManagerModule,
    LoadingSpinnerModule,
    BreadcrumbModule,
    FontAwesomeModule,
    AccordionModule,
  ],
  declarations: [HeaderComponent, ShellComponent],
})
export class ShellModule {}
