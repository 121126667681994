<p-panel
  header="Details"
  class="existing-member-information-display-card"
  data-cy="existing-member-information-display-card"
>
  <div class="existing-member-information-card">
    <div class="member-info-display-row">
      <div class="consumer-loyalty-program-panel-row">
        <label>Member Since</label>
        <span>{{ assignDateTime | date }}</span>
      </div>
      <div class="consumer-loyalty-program-panel-row">
        <label>Reward Balance</label>
        <span>{{ rewardBalance | currency }}</span>
      </div>
      <div class="consumer-loyalty-program-panel-row">
        <label>Lifetime Spend</label>
        <span>{{ amountTotal | currency }}</span>
      </div>
      <div class="consumer-loyalty-program-panel-row">
        <label>Average Spend</label>
        <span>{{ averageSpend | currency }}</span>
      </div>
      <div class="consumer-loyalty-program-panel-row">
        <label>Total Orders</label>
        <span>{{ totalOrders }}</span>
      </div>
    </div>
  </div>
</p-panel>
