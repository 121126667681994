import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ExistingReportDailySummaryTableComponent } from '@app/areas/reports/components/existing-report-daily-summary-table/existing-report-daily-summary-table.component';
import { ExistingBatchReportComponent } from '../existing-batch-report/existing-batch-report.component';
import { CommonModule, NgSwitchCase } from '@angular/common';

@Component({
  selector: 'app-existing-report',
  standalone: true,
  imports: [ExistingReportDailySummaryTableComponent, ExistingBatchReportComponent, CommonModule, NgSwitchCase],
  templateUrl: './existing-report.component.html',
  styleUrl: './existing-report.component.scss',
})
export class ExistingReportComponent implements OnInit {
  private readonly route = inject(ActivatedRoute);
  public reportUrl: string | undefined;

  public ngOnInit(): void {
    this.reportUrl = this.route.snapshot.url.map((segment) => segment.path).pop();
  }
}
