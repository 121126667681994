import { Injectable } from '@angular/core';
import { BaseApiService } from '@ep/shared';
import { Observable } from 'rxjs';

export interface BatchResponse {
  batchId: number;
  merchantBatchId: number;
  merchantId: number;
  deviceId: number;
  userId: number;
  closedDateTime: Date;
}

export interface BatchesResponse {
  $values: BatchResponse[];
}

export interface Batch {
  BatchId: number;
  MerchantBatchId: number;
  MerchantId: number;
  DeviceId: number;
  UserId: number;
  ClosedDateTime: Date;
}

export interface SettlementReportResponse {
  $values: SettlementReportItemResponse[];
}

export interface SettlementReportItemResponse {
  label: string;
  count?: number;
  amount: string;
}

export interface SettlementReportItem {
  Label: string;
  Count?: number;
  Amount: string;
}

export interface DailyBatchResponse {
  batchId: number;
  deviceId: number;
  userId: number;
  closeDate: Date;
  settlementReportItems: SettlementReportResponse;
}

@Injectable()
export class BatchService extends BaseApiService {
  private readonly reportEndpoint = '/v2/Reports';

  findAll(): Observable<BatchesResponse> {
    return this.get<BatchesResponse>(`${this.reportEndpoint}/batch-sales`);
  }

  findDailyBatchesById(batchId: number): Observable<DailyBatchResponse> {
    return this.get<DailyBatchResponse>(`${this.reportEndpoint}/batch-sales/${batchId}`);
  }

  findDailyBatchesByDate(searchDate: Date): Observable<BatchesResponse> {
    return this.get<BatchesResponse>(`${this.reportEndpoint}/batch-sales-daily`, {
      searchDate: searchDate.toLocaleDateString(),
    });
  }
}
