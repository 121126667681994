<div class="dashboard-overview-panel-data-wrapper" *ngIf="data">
  <span [style]="{ color: data.headerColor }" class="font-medium"> {{ data.header }} </span>
  <span [style]="{ color: data.subHeaderColor }"> {{ data.subHeader }} </span>

  <div>
    <span class="overview-panel-body text-overflow" [style]="{ color: data.bodyColor }">{{ data.body }}</span>
    <span>{{ data.subBody }}</span>
  </div>

  <span [style]="{ color: data.footerColor }"> {{ data.footer }} </span>
  <span [style]="{ color: data.footerColor }"> {{ data.subFooter }} </span>
</div>
