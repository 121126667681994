import { OverviewPanelData } from '@app/areas/dashboard/components/dashboard-overview-panel/dashboard-overview-panel.component';
import { ColorsEnum } from '@shared/enums/colors.enum';
import { ViewCardConfig } from '@app/areas/dashboard/components/view-card/view-card.component';
import {
  CustomChartJSData,
  DashboardChartJSData,
} from '@app/areas/dashboard/components/dashboard-graph/dashboard-graph.component';
import { DailySaleOverview } from '@shared/models/daily-sale-overview.model';
import moment from 'moment-timezone';
import { DashboardOverviewData } from '@app/areas/dashboard/components/abstract-dashboard-date-panel-view/abstract-dashboard-date-panel-view.component';
import { CurrencyPipe } from '@angular/common';

export function getDailySaleOverviewData(dashboardOverviewData?: DashboardOverviewData): OverviewPanelData[] {
  const _currency = new CurrencyPipe('en-US');
  return [
    {
      header: 'TOTAL',
      subHeader: `${dashboardOverviewData?.TotalOrderCount} Transactions`,
      body: `${_currency.transform(dashboardOverviewData?.TotalSpend ?? 0)}`,
      bodyColor: ColorsEnum.LIGHT_BLUE,
      footer: `${_currency.transform(dashboardOverviewData?.AverageSpend ?? 0)} Average`,
    },
    {
      header: 'MEMBER',
      subHeader: `${dashboardOverviewData?.TotalMemberOrderCount} Transactions (${(
        dashboardOverviewData?.MemberChecks ?? 0
      ).toFixed(2)}%)`,
      body: `${_currency.transform(dashboardOverviewData?.MemberSpend ?? 0)}`,
      bodyColor: ColorsEnum.MOSSY_GREEN,
      footer: `${_currency.transform(dashboardOverviewData?.AverageMemberSpend ?? 0)} Average`,
    },
    {
      header: 'NON-MEMBER',
      subHeader: `${dashboardOverviewData?.TotalNonMemberOrderCount} Transactions`,
      body: `${_currency.transform(dashboardOverviewData?.NonMemberSpend ?? 0)}`,
      bodyColor: ColorsEnum.DOC_PURPLE,
      footer: `${_currency.transform(dashboardOverviewData?.AverageNonMemberSpend ?? 0)} Average`,
    },
  ];
}

export function getDailyLoyaltyOverviewData(dashboardOverviewData?: DashboardOverviewData): OverviewPanelData[] {
  var averageWhenRedeeming = 0;
  if (
    dashboardOverviewData?.TotalOrdersWhenRedeemingCount &&
    dashboardOverviewData?.TotalOrdersWhenRedeemingCount !== 0
  ) {
    averageWhenRedeeming =
      dashboardOverviewData?.TotalAmountWhenRedeemingAmount / dashboardOverviewData?.TotalOrdersWhenRedeemingCount;
  }

  const _currency = new CurrencyPipe('en-US');
  return [
    {
      header: 'MEMBER CONVERSION',
      body: `${
        dashboardOverviewData?.EnrollmentConversion !== undefined && dashboardOverviewData?.EnrollmentConversion !== 0
          ? dashboardOverviewData.EnrollmentConversion.toFixed(2) + '%'
          : 'N/A'
      }`,
      footer: `${dashboardOverviewData?.TotalEnrollmentOrderCount ?? 0} Enrolled`,
      bodyColor: ColorsEnum.LIGHT_BLUE,
      subFooter: `${dashboardOverviewData?.TotalOpportunityCount} Opportunities`,
    },
    {
      header: 'ENROLLMENT TRANSACTIONS',
      body: `${_currency.transform(dashboardOverviewData?.EnrollmentTotalSpend ?? 0)}`,
      bodyColor: ColorsEnum.MOSSY_GREEN,
      footer: `${
        dashboardOverviewData?.RewardsTotalSpend !== 0
          ? _currency.transform(dashboardOverviewData?.TotalDiscountOrderAmount) + ' Enrollment Rewarded'
          : ''
      }`,
      subFooter: `${_currency.transform(dashboardOverviewData?.EnrollmentAverageSpend ?? 0)} Average Transaction`,
    },
    {
      header: 'REWARDS',
      body: `${_currency.transform(averageWhenRedeeming ?? 0)}`,
      bodyColor: ColorsEnum.DOC_PURPLE,
      subBody: 'Average When Redeeming',
      footer: `${_currency.transform(dashboardOverviewData?.EarnedRewardsTotal ?? 0)} Earned`,
      subFooter: `${_currency.transform(dashboardOverviewData?.RewardsTotalSpend ?? 0)} Redeemed`,
    },
  ];
}

export function getLoyaltyProgramData(dashboardOverviewData?: DashboardOverviewData): ViewCardConfig[] {
  const rawPercentage =
    (1 - (dashboardOverviewData?.RewardsTotalOrderSpend ?? 0) / (dashboardOverviewData?.TotalSpend ?? 1)) * 100;
  const percentage = isNaN(rawPercentage) ? 0 : rawPercentage;

  const _currency = new CurrencyPipe('en-US');
  return [
    {
      header: `${_currency.transform(dashboardOverviewData?.TotalSpend ?? 0)}`,
      headerSubtext: '',
      subheader: 'SALES',
      text: `\u00A0`,
      backgroundColor: ColorsEnum.LIGHT_BLUE,
      pathToImg: '../../../../../assets/images/line-graph.png',
    },
    {
      header: `${dashboardOverviewData?.TotalEnrollmentOrderCount ?? 0}`,
      headerSubtext: 'Enrolled',
      subheader: 'LOYALTY',
      text: `${dashboardOverviewData?.EnrollmentCount} Current `,
      backgroundColor: ColorsEnum.MOSSY_GREEN,
      pathToImg: '../../../../../assets/images/handshake.png',
    },
    {
      header: ` ${
        dashboardOverviewData?.RewardsTotalOrderSpend !== null && dashboardOverviewData?.RewardsTotalOrderSpend !== 0
          ? percentage.toFixed(2) + '%'
          : 'N/A'
      } `,
      headerSubtext: ` ${
        dashboardOverviewData?.RewardsTotalOrderSpend !== null && dashboardOverviewData?.RewardsTotalOrderSpend !== 0
          ? 'ROI'
          : ''
      }`,
      subheader: 'REWARDS',
      text: `${_currency.transform(dashboardOverviewData?.MemberSpend)} Members Sales | ${_currency.transform(
        dashboardOverviewData?.RewardsTotalOrderSpend ?? 0
      )} Redeemed`,
      backgroundColor: ColorsEnum.DOC_PURPLE,
      pathToImg: '../../../../../assets/images/thumbs-up.png',
    },
    {
      header: `${
        !!dashboardOverviewData?.TotalQuestionAnswerCount ? getQuestionPercentageScore(dashboardOverviewData) : 'N/A'
      }`,
      headerSubtext: !!dashboardOverviewData?.TotalQuestionAnswerCount ? 'Average Score' : null,
      subheader: 'REVIEWS',
      text: `${dashboardOverviewData?.TotalQuestionAnswerCount ?? 0} Responses`,
      backgroundColor: ColorsEnum.BLUE_GREY,
      pathToImg: '../../../../../assets/images/star.png',
    },
  ];
}

function getQuestionPercentageScore(dashboardOverviewData?: DashboardOverviewData) {
  if (!dashboardOverviewData) return 'N/A';
  let thumbsUpAnswers = 0;
  let numberOfThumbsUpAnswers = 0;

  for (let i = dashboardOverviewData.TotalQuestionAnswerCount; i > 0; i--) {
    thumbsUpAnswers = i * 5;
    if (thumbsUpAnswers === dashboardOverviewData.TotalQuestionAnswerScore) {
      numberOfThumbsUpAnswers = i;
      break;
    }
    thumbsUpAnswers = 0;
  }

  let percentage = (numberOfThumbsUpAnswers / dashboardOverviewData.TotalQuestionAnswerCount) * 100;
  return `${percentage.toFixed(2)}%`;
}

export function getPrefilledChartJSData(label: string, borderColor: string = ColorsEnum.LIGHT_BLUE): CustomChartJSData {
  return {
    label: label,
    data: [],
    fill: false,
    borderColor: borderColor,
    backgroundColor: borderColor,
    tension: 0.4,
    order: 0,
  };
}

export function getDashboardData(data?: DailySaleOverview[] | null) {
  let dashboardOverviewData: DashboardOverviewData = {
    RewardsTotalOrderSpend: 0,
    RewardsTotalSpend: 0,
    TotalQuestionAnswerCount: 0,
    TotalQuestionAnswerScore: 0,
    TotalQuestionAnswerAverage: 0,
    TotalSpend: 0,
    AverageSpend: 0,
    NonMemberSpend: 0,
    AverageNonMemberSpend: 0,
    MemberSpend: 0,
    AverageMemberSpend: 0,
    MemberChecks: 0,
    EnrollmentCount: 0,
    EnrollmentConversion: 0,
    EnrollmentTotalSpend: 0,
    EnrollmentAverageSpend: 0,
    NewEnrollmentTotalSpend: 0,
    NewEnrollmentAverageTotalSpend: 0,
    EarnedRewardsTotal: 0,
    TotalMemberOrderCount: 0,
    TotalNonMemberOrderCount: 0,
    TotalEnrollmentOrderCount: 0,
    TotalOrderCount: 0,
    TotalOpportunityCount: 0,
    TotalEnrollmentCancelledCount: 0,
    TotalDiscountOrderAmount: 0,
    AverageAmountTotal: 0,
    TotalOrdersWhenRedeemingCount: 0,
    TotalAmountWhenRedeemingAmount: 0,
  };

  let dashboardChartData: DashboardChartJSData = {
    labels: [],
    datasets: [
      {
        ...getPrefilledChartJSData('Total Sales', ColorsEnum.LIGHT_BLUE),
      },
      {
        ...getPrefilledChartJSData('Member Sales', ColorsEnum.MOSSY_GREEN),
      },
      {
        ...getPrefilledChartJSData('Non-Member Sales', ColorsEnum.BLUE_GREY),
      },
    ],
  };

  if (data) {
    for (let i = 0; i < data.length; i++) {
      // Set Overview data
      dashboardOverviewData.TotalSpend +=
        data[i].TotalNonMemberSpendAmount + data[i].TotalMemberSpendAmount + data[i].TotalEnrollmentSpendAmount;
      dashboardOverviewData.NonMemberSpend += data[i].TotalNonMemberSpendAmount;
      dashboardOverviewData.MemberSpend += data[i].TotalMemberSpendAmount + data[i].TotalEnrollmentSpendAmount;
      dashboardOverviewData.TotalOrderCount +=
        data[i].TotalNonMemberOrderCount + data[i].TotalMemberOrderCount + data[i].TotalEnrollmentOrderCount;
      dashboardOverviewData.TotalNonMemberOrderCount += data[i].TotalNonMemberOrderCount;
      dashboardOverviewData.TotalMemberOrderCount += data[i].TotalMemberOrderCount + data[i].TotalEnrollmentOrderCount;
      dashboardOverviewData.EnrollmentCount = data[i].EnrollmentCount - data[i].TotalEnrollmentCancelledCount;
      // dashboardOverviewData.EnrollmentConversion += data[i].TotalMemberSpendAmount + data[i].TotalEnrollmentSpendAmount;
      dashboardOverviewData.EnrollmentTotalSpend += data[i].TotalEnrollmentSpendAmount;
      dashboardOverviewData.NewEnrollmentTotalSpend += data[i].TotalEnrollmentSpendAmount;
      dashboardOverviewData.EarnedRewardsTotal += data[i].TotalRewardEarnedAmount;
      dashboardOverviewData.TotalEnrollmentOrderCount += data[i].TotalEnrollmentOrderCount;
      dashboardOverviewData.RewardsTotalSpend += data[i].TotalRewardSpentAmount;
      dashboardOverviewData.RewardsTotalOrderSpend += data[i].TotalRewardSpentOrderAmount;
      dashboardOverviewData.TotalQuestionAnswerCount += data[i].TotalQuestionAnswerCount;
      dashboardOverviewData.TotalQuestionAnswerScore += data[i].TotalQuestionAnswerScore;
      dashboardOverviewData.TotalOpportunityCount += data[i].TotalOpportunityCount;
      dashboardOverviewData.TotalEnrollmentCancelledCount = data[i].TotalEnrollmentCancelledCount;
      dashboardOverviewData.TotalDiscountOrderAmount += data[i].TotalDiscountOrderAmount;
      dashboardOverviewData.AverageAmountTotal += data[i].AverageAmountTotal;
      dashboardOverviewData.TotalOrdersWhenRedeemingCount += data[i].TotalOrdersWhenRedeemingCount;
      dashboardOverviewData.TotalAmountWhenRedeemingAmount += data[i].TotalAmountWhenRedeemingAmount;

      // Set Chart data
      dashboardChartData.labels.push(moment(data[i].DailyDate).format('MM-DD-YYYY'));
      // Insert Total Sales data
      dashboardChartData.datasets[0].data.push(
        data[i].TotalNonMemberSpendAmount + data[i].TotalMemberSpendAmount + data[i].TotalEnrollmentSpendAmount
      );
      // Insert Member Sales data
      dashboardChartData.datasets[1].data.push(data[i].TotalMemberSpendAmount + data[i].TotalEnrollmentSpendAmount);
      // Insert Non-Member Sales data
      dashboardChartData.datasets[2].data.push(data[i].TotalNonMemberSpendAmount);
    }

    if (dashboardOverviewData.TotalOrderCount !== 0) {
      dashboardOverviewData.AverageSpend = dashboardOverviewData.TotalSpend / dashboardOverviewData.TotalOrderCount;
      dashboardOverviewData.MemberChecks =
        (dashboardOverviewData.TotalMemberOrderCount / dashboardOverviewData.TotalOrderCount) * 100;
    }
    if (dashboardOverviewData.TotalMemberOrderCount !== 0)
      dashboardOverviewData.AverageMemberSpend =
        dashboardOverviewData.MemberSpend / dashboardOverviewData.TotalMemberOrderCount;
    if (dashboardOverviewData.TotalNonMemberOrderCount !== 0)
      dashboardOverviewData.AverageNonMemberSpend =
        dashboardOverviewData.NonMemberSpend / dashboardOverviewData.TotalNonMemberOrderCount;

    if (dashboardOverviewData.TotalOpportunityCount > 0 && dashboardOverviewData.TotalEnrollmentOrderCount > 0) {
      dashboardOverviewData.EnrollmentConversion =
        (dashboardOverviewData.TotalEnrollmentOrderCount / dashboardOverviewData.TotalOpportunityCount) * 100;
    }

    if (dashboardOverviewData.TotalEnrollmentOrderCount) {
      dashboardOverviewData.EnrollmentAverageSpend =
        dashboardOverviewData.EnrollmentTotalSpend / dashboardOverviewData.TotalEnrollmentOrderCount;
    }
    if (dashboardOverviewData.TotalQuestionAnswerCount !== 0) {
      dashboardOverviewData.TotalQuestionAnswerAverage =
        dashboardOverviewData.TotalQuestionAnswerScore / dashboardOverviewData.TotalQuestionAnswerCount;
    }
  }

  return { dashboardOverviewData: dashboardOverviewData, dashboardChartData: dashboardChartData };
}
