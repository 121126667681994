import { Component, inject, input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { PanelModule } from 'primeng/panel';
import { PrimeTemplate } from 'primeng/api';
import { OrderPayment } from '@app/resources/services';
import { CurrencyPipe, NgIf } from '@angular/common';
import { ApplicationProfileService } from '@app/@shared/services/application-profile.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { getEnvironment } from '@app/@shared/utils';

@Component({
  selector: 'app-existing-transaction-payment-details',
  standalone: true,
  imports: [PanelModule, PrimeTemplate, CurrencyPipe, NgIf],
  templateUrl: './existing-transaction-payment-details.component.html',
})
export class ExistingTransactionPaymentDetailsComponent implements OnChanges {
  public readonly applicationProfileService = inject(ApplicationProfileService);
  public readonly sanitizer = inject(DomSanitizer);

  payment = input.required<OrderPayment>();
  protected iframeSrc: SafeResourceUrl = '';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['payment'] && this.payment()) {
      this.loadReceiptIframe();
    }
  }

  protected loadReceiptIframe(): void {
    const environment: string = getEnvironment();
    const environmentUrls: { [key: string]: string } = {
      staging: 'https://linx-staging.enrollandpay.com',
      preprod: 'https://linx-preprod.enrollandpay.com',
      prod: 'https://linx.enrollandpay.com',
    };

    const linxUrl = environmentUrls[environment] || 'https://linx.enrollandpay.com';
    const url = `${linxUrl}/#SEReceipt=${this.payment().OrderPaymentGuid}&f=true`;
    this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
