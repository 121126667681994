import { ChangeDetectorRef, Component, inject, Input, OnInit } from '@angular/core';
import { PanelModule } from 'primeng/panel';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { Observable } from 'rxjs';
import { DividerModule } from 'primeng/divider';
import { ColorsEnum } from '@shared/enums/colors.enum';
import { DashboardOverviewPanelDataComponent } from '@app/areas/dashboard/components/dashboard-overview-panel-data/dashboard-overview-panel-data.component';
import {
  DashboardChartJSData,
  DashboardGraphComponent,
} from '@app/areas/dashboard/components/dashboard-graph/dashboard-graph.component';

export interface OverviewPanelData {
  header: string;
  headerColor?: ColorsEnum;
  subHeader?: string;
  subHeaderColor?: ColorsEnum;
  body: string;
  bodyColor?: ColorsEnum;
  subBody?: string;
  footer: string;
  subFooter?: string;
  footerColor?: ColorsEnum;
}

@Component({
  selector: 'app-dashboard-overview-panel',
  templateUrl: './dashboard-overview-panel.component.html',
  styleUrls: ['./dashboard-overview-panel.component.scss'],
  imports: [
    PanelModule,
    NgIf,
    NgForOf,
    AsyncPipe,
    DividerModule,
    DashboardOverviewPanelDataComponent,
    DashboardGraphComponent,
  ],
  standalone: true,
})
export class DashboardOverviewPanelComponent implements OnInit {
  @Input() header: string = '';
  @Input() data$!: Observable<OverviewPanelData[]>;
  @Input() showChartData: boolean = false;
  @Input() graphData$: Observable<DashboardChartJSData> | null = null;

  private readonly cdr = inject(ChangeDetectorRef);
  protected overviews: OverviewPanelData[] = [];

  ngOnInit() {
    this.data$.subscribe((data) => {
      this.overviews = data;
      this.cdr.detectChanges();
    });
  }
}
