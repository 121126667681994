import {
  Report,
  DailySalesReportResponse,
  DailySalesReport,
  DailySalesReportsResponse,
  ReportResponse,
  ReportsResponse,
} from '@app/resources/services';
import {
  Batch,
  BatchesResponse,
  BatchResponse,
  DailyBatchResponse,
  SettlementReportItem,
} from '@app/resources/services/batch.service';
import { isNotNullOrUndefined } from '@ep/shared';

export const mapToReport = (data: ReportResponse): Report => {
  return {
    ReportId: data.reportId,
    NamePrivate: data.namePrivate,
    Display: typeof data.display === 'string' ? JSON.parse(data.display)['en-US'] : data.display['en-US'],
    StatusType: data.statusType,
    ReportContextType: data.reportContextType,
  };
};

export const mapToReports = (data: ReportsResponse): Report[] => {
  return data.$values.map(mapToReport);
};

export const mapToDailySalesReport = (data: DailySalesReportResponse): DailySalesReport => {
  return {
    Metric: data.metric,
    Count: data.count,
    Amount: data.amount,
  };
};

export const mapToDailySalesReports = (data: DailySalesReportsResponse): DailySalesReport[] => {
  return data.$values.map(mapToDailySalesReport);
};

// Batch Report Mappers
export const mapToBatch = (data: BatchResponse): Batch => {
  return {
    BatchId: data.batchId,
    MerchantBatchId: data.merchantBatchId,
    MerchantId: data.merchantId,
    DeviceId: data.deviceId,
    UserId: data.userId,
    ClosedDateTime: data.closedDateTime,
  };
};

export const mapToBatches = (data: BatchesResponse): Batch[] => {
  return data.$values.map(mapToBatch);
};

export const mapToBatchDetail = (data: DailyBatchResponse): SettlementReportItem[] => {
  return data.settlementReportItems.$values.map((item) => {
    return {
      Label: item.label,
      Count: item.count,
      Amount: item.amount,
    };
  });
};
