<div
  class="app-shell-wrapper"
  [ngStyle]="{ 'flex-direction': isMobileScreen ? 'column' : 'row' }"
  data-cy="app-shell-wrapper"
>
  <!-- Mobile Navigation Bar -->
  <div *ngIf="isMobileScreen" class="mobile-dropdown">
    <div class="mobile-header">
      <img
        [src]="
          subdomainType === SubdomainTypes.EagleProcessing
            ? '../../assets/eagle-logo.png'
            : '../../assets/enroll-and-pay-logo.png'
        "
        alt="image"
        class="logo-image"
        class="logo-image"
      />

      <fa-icon
        [icon]="icons['faBars']"
        class="header-icon"
        (click)="onToggleMobileDropdown()"
        data-cy="mobile-nav-toggle"
      ></fa-icon>
    </div>

    <div class="dropdown-content" *ngIf="showMobileDropdown">
      <div class="d-flex flex-column">
        <a
          *ngIf="shouldShowMenuItem(AccessType.SaleOverview)"
          class="nav-link"
          [routerLink]="this.applicationProfile.Location.LocationId + '/dashboard'"
          routerLinkActive="active"
          [pTooltip]="isSmallScreen ? 'Dashboard' : ''"
          (click)="onToggleMobileDropdown()"
        >
          <div class="nav-link-content">
            <fa-icon [icon]="icons['faChartBar']" class="mr-3" />
            <span class="nav-label">Dashboard</span>
          </div>
        </a>
        <a
          *ngIf="shouldShowMenuItem(AccessType.OfferAdmin)"
          class="nav-link"
          [routerLink]="this.applicationProfile.Location.LocationId + '/offers'"
          routerLinkActive="active"
          [pTooltip]="isSmallScreen ? 'Offers' : ''"
          data-cy="nav-offers-mobile"
          (click)="onToggleMobileDropdown()"
        >
          <div class="nav-link-content">
            <fa-icon [icon]="icons['faMoneyBill']" [fixedWidth]="true" class="mr-3" />
            <span class="nav-label">Offers</span>
          </div>
        </a>
        <a
          *ngIf="shouldShowMenuItem(AccessType.ConsumerAccess)"
          class="nav-link"
          [routerLink]="this.applicationProfile.Location.LocationId + '/members'"
          routerLinkActive="active"
          [pTooltip]="isSmallScreen ? 'Members' : ''"
          (click)="onToggleMobileDropdown()"
        >
          <div class="nav-link-content">
            <fa-icon [icon]="icons['faUsers']" [fixedWidth]="true" class="mr-3" />
            <span class="nav-label">Members</span>
          </div>
        </a>
        <a
          *ngIf="shouldShowMenuItem(AccessType.OrderAdmin)"
          class="nav-link"
          [routerLink]="this.applicationProfile.Location.LocationId + '/transactions'"
          routerLinkActive="active"
          [pTooltip]="isSmallScreen ? 'Transactions' : ''"
          (click)="onToggleMobileDropdown()"
        >
          <div class="nav-link-content">
            <fa-icon [icon]="icons['faCreditCard']" [fixedWidth]="true" class="mr-3" />
            <span class="nav-label">Transactions</span>
          </div>
        </a>
        <a
          *ngIf="shouldShowMenuItem(AccessType.ReportAdmin)"
          class="nav-link"
          [routerLink]="this.applicationProfile.Location.LocationId + '/reports'"
          routerLinkActive="active"
          [pTooltip]="isSmallScreen ? 'Reports' : ''"
          (click)="onToggleMobileDropdown()"
        >
          <div class="nav-link-content">
            <fa-icon [icon]="icons['faFileExport']" [fixedWidth]="true" class="mr-3" />
            <span class="nav-label">Reports</span>
          </div>
        </a>
      </div>
      <!-- Change the activeIndex to 0 when the Merchant Accordion is back -->
      <p-accordion [activeIndex]="2" [collapseIcon]="" [expandIcon]="">
        <!-- <p-accordionTab>
          <ng-template pTemplate="header">
            <div class="accordionNav">
              <div class="text-white d-flex gap-3 ml-1">
                <fa-icon [icon]="icons['faHouse']" />
                <span class="text-white"> Merchant </span>
              </div>
            </div>
          </ng-template>

          <div class="d-flex flex-column">
            <a
              *ngIf="shouldShowMenuItem(AccessType.SaleOverview)"
              class="nav-link"
              [routerLink]="this.applicationProfile.Location.LocationId + '/dashboard'"
              routerLinkActive="active"
              [pTooltip]="isSmallScreen ? 'Dashboard' : ''"
            >
              <div class="nav-link-content">
                <fa-icon [icon]="icons['faChartBar']" class="mr-3" />
                <span class="nav-label">Dashboard</span>
              </div>
            </a>
            <a
              *ngIf="shouldShowMenuItem(AccessType.OfferAdmin)"
              class="nav-link"
              [routerLink]="this.applicationProfile.Location.LocationId + '/offers'"
              routerLinkActive="active"
              [pTooltip]="isSmallScreen ? 'Offers' : ''"
              data-cy="nav-offers-mobile"
            >
              <div class="nav-link-content">
                <fa-icon [icon]="icons['faMoneyBill']" [fixedWidth]="true" class="mr-3" />
                <span class="nav-label">Offers</span>
              </div>
            </a>
            <a
              *ngIf="shouldShowMenuItem(AccessType.ConsumerAccess)"
              class="nav-link"
              [routerLink]="this.applicationProfile.Location.LocationId + '/members'"
              routerLinkActive="active"
              [pTooltip]="isSmallScreen ? 'Members' : ''"
            >
              <div class="nav-link-content">
                <fa-icon [icon]="icons['faUsers']" [fixedWidth]="true" class="mr-3" />
                <span class="nav-label">Members</span>
              </div>
            </a>
            <a
              *ngIf="shouldShowMenuItem(AccessType.OrderAdmin)"
              class="nav-link"
              [routerLink]="this.applicationProfile.Location.LocationId + '/transactions'"
              routerLinkActive="active"
              [pTooltip]="isSmallScreen ? 'Transactions' : ''"
            >
              <div class="nav-link-content">
                <fa-icon [icon]="icons['faCreditCard']" [fixedWidth]="true" class="mr-3" />
                <span class="nav-label">Transactions</span>
              </div>
            </a>
            <a
              *ngIf="shouldShowMenuItem(AccessType.ReportAdmin)"
              class="nav-link"
              [routerLink]="this.applicationProfile.Location.LocationId + '/reports'"
              routerLinkActive="active"
              [pTooltip]="isSmallScreen ? 'Reports' : ''"
            >
              <div class="nav-link-content">
                <fa-icon [icon]="icons['faFileExport']" [fixedWidth]="true" class="mr-3" />
                <span class="nav-label">Reports</span>
              </div>
            </a>
          </div>
        </p-accordionTab> -->
        <p-accordionTab *ngIf="shouldShowMenuItem(AccessType.OrderAdmin)" iconPos="end">
          <ng-template pTemplate="header">
            <div class="accordionNav">
              <div class="text-white d-flex gap-3 ml-1">
                <fa-icon [icon]="icons['faGear']" />
                <span class="text-white" class="mr-3"> Admin </span>
              </div>
            </div>
          </ng-template>
          <div class="d-flex flex-column">
            <a
              class="nav-link"
              [routerLink]="this.applicationProfile.Location.LocationId + '/admin/devices'"
              routerLinkActive="active"
              [pTooltip]="isSmallScreen ? 'Devices' : ''"
              (click)="onToggleMobileDropdown()"
            >
              <div class="nav-link-content">
                <fa-icon [icon]="icons['faCalculator']" [fixedWidth]="true" class="mr-3" />
                <span class="nav-label">Devices</span>
              </div>
            </a>

            <a
              class="nav-link"
              [routerLink]="this.applicationProfile.Location.LocationId + '/admin/locations'"
              routerLinkActive="active"
              [pTooltip]="isSmallScreen ? 'Locations' : ''"
              (click)="onToggleMobileDropdown()"
            >
              <div class="nav-link-content">
                <fa-icon [icon]="icons['faStore']" [fixedWidth]="true" class="mr-3" />
                <span class="nav-label">Locations</span>
              </div>
            </a>

            <a
              class="nav-link"
              [routerLink]="this.applicationProfile.Location.LocationId + '/admin/employees'"
              routerLinkActive="active"
              [pTooltip]="isSmallScreen ? 'Employees' : ''"
              (click)="onToggleMobileDropdown()"
            >
              <div class="nav-link-content">
                <fa-icon [icon]="icons['faUsersCog']" [fixedWidth]="true" class="mr-3" />
                <span class="nav-label">Employees</span>
              </div>
            </a>

            <a
              class="nav-link"
              [routerLink]="this.applicationProfile.Location.LocationId + '/admin/loyalty-program'"
              routerLinkActive="active"
              [pTooltip]="isSmallScreen ? 'Loyalty Program' : ''"
              (click)="onToggleMobileDropdown()"
            >
              <div class="nav-link-content">
                <fa-icon [icon]="icons['faStar']" [fixedWidth]="true" class="mr-3" />
                <span class="nav-label">Loyalty Program</span>
              </div>
            </a>

            <a
              class="nav-link"
              [routerLink]="this.applicationProfile.Location.LocationId + '/admin/questions'"
              routerLinkActive="active"
              [pTooltip]="isSmallScreen ? 'Questions' : ''"
              (click)="onToggleMobileDropdown()"
            >
              <div class="nav-link-content">
                <fa-icon [icon]="icons['faPollH']" [fixedWidth]="true" class="mr-3" />
                <span class="nav-label">Questions</span>
              </div>
            </a>

            <a
              class="nav-link"
              [routerLink]="this.applicationProfile.Location.LocationId + '/admin/invoices'"
              routerLinkActive="active"
              [pTooltip]="isSmallScreen ? 'Invoices' : ''"
              (click)="onToggleMobileDropdown()"
            >
              <div class="nav-link-content">
                <fa-icon [icon]="icons['faFileInvoice']" [fixedWidth]="true" class="mr-3" />
                <span class="nav-label">Invoices</span>
              </div>
            </a>
          </div>
        </p-accordionTab>
      </p-accordion>
    </div>
  </div>

  <!-- Tablet/Desktop Navigation Bar -->
  <div
    *ngIf="!isMobileScreen"
    [ngClass]="{
      'minimized-sidebar': (isSmallScreen && !shouldShowFullWidth) || !shouldShowFullWidth
    }"
  >
    <div class="side-navigation-bar">
      <div class="side-navigation-bar-header">
        <img [src]="getImageSrc()" alt="image" class="logo-image" />
      </div>

      <div class="d-flex flex-column">
        <a
          *ngIf="shouldShowMenuItem(AccessType.SaleOverview)"
          class="nav-link"
          [routerLink]="this.applicationProfile.Location.LocationId + '/dashboard'"
          routerLinkActive="active"
          [pTooltip]="isSmallScreen ? 'Dashboard' : ''"
        >
          <div class="nav-link-content">
            <fa-icon [icon]="icons['faChartBar']" size="lg" class="ml-1" />
            <span class="nav-label">Dashboard</span>
          </div>
        </a>
        <a
          *ngIf="shouldShowMenuItem(AccessType.OfferAdmin)"
          class="nav-link"
          [routerLink]="this.applicationProfile.Location.LocationId + '/offers'"
          routerLinkActive="active"
          [pTooltip]="isSmallScreen ? 'Offers' : ''"
          data-cy="nav-offers-mobile"
        >
          <div class="nav-link-content">
            <fa-icon [icon]="icons['faMoneyBill']" size="lg" [fixedWidth]="true" class="ml-1" />
            <span class="nav-label">Offers</span>
          </div>
        </a>
        <a
          *ngIf="shouldShowMenuItem(AccessType.ConsumerAccess)"
          class="nav-link"
          [routerLink]="this.applicationProfile.Location.LocationId + '/members'"
          routerLinkActive="active"
          [pTooltip]="isSmallScreen ? 'Members' : ''"
        >
          <div class="nav-link-content">
            <fa-icon [icon]="icons['faUsers']" size="lg" [fixedWidth]="true" class="ml-1" />
            <span class="nav-label">Members</span>
          </div>
        </a>
        <a
          *ngIf="shouldShowMenuItem(AccessType.OrderAdmin)"
          class="nav-link"
          [routerLink]="this.applicationProfile.Location.LocationId + '/transactions'"
          routerLinkActive="active"
          [pTooltip]="isSmallScreen ? 'Transactions' : ''"
        >
          <div class="nav-link-content">
            <fa-icon [icon]="icons['faCreditCard']" size="lg" [fixedWidth]="true" class="ml-1" />
            <span class="nav-label">Transactions</span>
          </div>
        </a>
        <a
          *ngIf="shouldShowMenuItem(AccessType.ReportAdmin)"
          class="nav-link"
          [routerLink]="this.applicationProfile.Location.LocationId + '/reports'"
          routerLinkActive="active"
          [pTooltip]="isSmallScreen ? 'Reports' : ''"
        >
          <div class="nav-link-content">
            <fa-icon [icon]="icons['faFileExport']" size="lg" [fixedWidth]="true" class="ml-1" />
            <span class="nav-label">Reports</span>
          </div>
        </a>
      </div>

      <!-- Your side navigation bar content here -->
      <p-accordion [activeIndex]="activeMenuAccordionIndex" [collapseIcon]="" [expandIcon]="">
        <!-- Accordion for Merchant -->
        <!-- <p-accordionTab>
          <ng-template pTemplate="header">
            <div class="accordionNav">
              <div class="text-white d-flex gap-3 ml-1">
                <fa-icon [icon]="icons['faHouse']" size="lg" />
                <span *ngIf="shouldShowFullWidth" class="text-white"> Merchant </span>
              </div>
            </div>
          </ng-template>

          <div class="d-flex flex-column">
            <a
              *ngIf="shouldShowMenuItem(AccessType.SaleOverview)"
              class="nav-link"
              [routerLink]="this.applicationProfile.Location.LocationId + '/dashboard'"
              routerLinkActive="active"
              [pTooltip]="isSmallScreen ? 'Dashboard' : ''"
            >
              <div class="nav-link-content">
                <fa-icon [icon]="icons['faChartBar']" size="lg" class="ml-1" />
                <span class="nav-label">Dashboard</span>
              </div>
            </a>
            <a
              *ngIf="shouldShowMenuItem(AccessType.OfferAdmin)"
              class="nav-link"
              [routerLink]="this.applicationProfile.Location.LocationId + '/offers'"
              routerLinkActive="active"
              [pTooltip]="isSmallScreen ? 'Offers' : ''"
              data-cy="nav-offers-mobile"
            >
              <div class="nav-link-content">
                <fa-icon [icon]="icons['faMoneyBill']" size="lg" [fixedWidth]="true" class="ml-1" />
                <span class="nav-label">Offers</span>
              </div>
            </a>
            <a
              *ngIf="shouldShowMenuItem(AccessType.ConsumerAccess)"
              class="nav-link"
              [routerLink]="this.applicationProfile.Location.LocationId + '/members'"
              routerLinkActive="active"
              [pTooltip]="isSmallScreen ? 'Members' : ''"
            >
              <div class="nav-link-content">
                <fa-icon [icon]="icons['faUsers']" size="lg" [fixedWidth]="true" class="ml-1" />
                <span class="nav-label">Members</span>
              </div>
            </a>
            <a
              *ngIf="shouldShowMenuItem(AccessType.OrderAdmin)"
              class="nav-link"
              [routerLink]="this.applicationProfile.Location.LocationId + '/transactions'"
              routerLinkActive="active"
              [pTooltip]="isSmallScreen ? 'Transactions' : ''"
            >
              <div class="nav-link-content">
                <fa-icon [icon]="icons['faCreditCard']" size="lg" [fixedWidth]="true" class="ml-1" />
                <span class="nav-label">Transactions</span>
              </div>
            </a>
            <a
              *ngIf="shouldShowMenuItem(AccessType.ReportAdmin)"
              class="nav-link"
              [routerLink]="this.applicationProfile.Location.LocationId + '/reports'"
              routerLinkActive="active"
              [pTooltip]="isSmallScreen ? 'Reports' : ''"
            >
              <div class="nav-link-content">
                <fa-icon [icon]="icons['faFileExport']" size="lg" [fixedWidth]="true" class="ml-1" />
                <span class="nav-label">Reports</span>
              </div>
            </a>
          </div>
        </p-accordionTab> -->
        <p-accordionTab *ngIf="shouldShowMenuItem(AccessType.OrderAdmin)" iconPos="end">
          <ng-template pTemplate="header">
            <div class="accordionNav">
              <div class="text-white d-flex gap-3 ml-1">
                <fa-icon [icon]="icons['faGear']" size="lg" />

                <span *ngIf="shouldShowFullWidth" class="text-white" class="ml-1"> Admin </span>
              </div>
            </div>
          </ng-template>
          <div class="d-flex flex-column">
            <a
              class="nav-link"
              [routerLink]="this.applicationProfile.Location.LocationId + '/admin/devices'"
              routerLinkActive="active"
              [pTooltip]="isSmallScreen ? 'Devices' : ''"
            >
              <div class="nav-link-content">
                <fa-icon [icon]="icons['faCalculator']" size="lg" [fixedWidth]="true" class="ml-1" />
                <span class="nav-label">Devices</span>
              </div>
            </a>

            <a
              class="nav-link"
              [routerLink]="this.applicationProfile.Location.LocationId + '/admin/locations'"
              routerLinkActive="active"
              [pTooltip]="isSmallScreen ? 'Locations' : ''"
            >
              <div class="nav-link-content">
                <fa-icon [icon]="icons['faStore']" size="lg" [fixedWidth]="true" class="ml-1" />
                <span class="nav-label">Locations</span>
              </div>
            </a>

            <a
              class="nav-link"
              [routerLink]="this.applicationProfile.Location.LocationId + '/admin/employees'"
              routerLinkActive="active"
              [pTooltip]="isSmallScreen ? 'Employees' : ''"
            >
              <div class="nav-link-content">
                <fa-icon [icon]="icons['faUsersCog']" size="lg" [fixedWidth]="true" class="ml-1" />
                <span class="nav-label">Employees</span>
              </div>
            </a>

            <a
              class="nav-link"
              [routerLink]="this.applicationProfile.Location.LocationId + '/admin/loyalty-program'"
              routerLinkActive="active"
              [pTooltip]="isSmallScreen ? 'Loyalty Program' : ''"
            >
              <div class="nav-link-content">
                <fa-icon [icon]="icons['faStar']" size="lg" [fixedWidth]="true" class="ml-1" />
                <span class="nav-label">Loyalty Program</span>
              </div>
            </a>

            <a
              class="nav-link"
              [routerLink]="this.applicationProfile.Location.LocationId + '/admin/questions'"
              routerLinkActive="active"
              [pTooltip]="isSmallScreen ? 'Questions' : ''"
            >
              <div class="nav-link-content">
                <fa-icon [icon]="icons['faPollH']" size="lg" [fixedWidth]="true" class="ml-1" />
                <span class="nav-label">Questions</span>
              </div>
            </a>

            <a
              class="nav-link"
              [routerLink]="this.applicationProfile.Location.LocationId + '/admin/invoices'"
              routerLinkActive="active"
              [pTooltip]="isSmallScreen ? 'Invoices' : ''"
            >
              <div class="nav-link-content">
                <fa-icon [icon]="icons['faFileInvoice']" size="lg" [fixedWidth]="true" class="ml-1" />
                <span class="nav-label">Invoices</span>
              </div>
            </a>
          </div>
        </p-accordionTab>
      </p-accordion>
    </div>
  </div>

  <!-- Main Content -->
  <div class="main-content">
    <app-header (closeClicked)="onCloseClicked()"></app-header>
    <div class="app-content">
      <!-- Your main content, including header and router-outlet -->
      <p-breadcrumb *ngIf="shouldShowBreadcrumbs" [model]="(breadcrumbs$ | async) || []">
        <ng-template pTemplate="item" let-item>
          <ng-container>
            <div (click)="item.command()" class="breadcrumb-item">
              <fa-icon *ngIf="item.icon" [icon]="icons[item.icon]" size="xl"></fa-icon>
              <span>{{ item.label }}</span>
            </div>
          </ng-container>
        </ng-template>
      </p-breadcrumb>
      <ep-iframe-manager
        #iframeManager
        id="iframe-manager"
        [iframeWidth]="iframeWidth"
        [iframeHeight]="iframeHeight"
        [ngClass]="{ hidden: isLoading || !shouldShowIframe }"
        data-cy="iframe-manager"
      ></ep-iframe-manager>
      <div *ngIf="!isLoading && !shouldShowIframe" id="merchant-portal-routing-wrapper">
        <router-outlet></router-outlet>
      </div>
      <app-loading-spinner
        *ngIf="isLoading || (shouldShowIframe && !isLegacyLoaded)"
        [message]="'Loading...'"
        class="text-center"
      ></app-loading-spinner>
    </div>
  </div>
</div>
