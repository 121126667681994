import { Component, computed, inject, input, Signal } from '@angular/core';
import { CardModule } from 'primeng/card';
import { ExistingMemberInformationComponent } from '@app/areas/members/components/existing-member-information/existing-member-information.component';
import { ExistingMemberRecentTransactionsListComponent } from '@app/areas/members/components';
import { PrimeTemplate } from 'primeng/api';
import { Consumer } from '@app/resources/services';
import { PanelModule } from 'primeng/panel';
import { FA_ICON, FONT_AWESOME_ICONS, isNotNullOrUndefined } from '@ep/shared';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { FormatPhonePipe } from '@shared/pipes';
import { NgIf } from '@angular/common';
import { AvatarModule } from 'primeng/avatar';
import { Store } from '@ngrx/store';
import { RouterActions } from '@app/resources/ngrx/actions';

@Component({
  selector: 'app-existing-transaction-consumer-details',
  standalone: true,
  imports: [
    CardModule,
    ExistingMemberInformationComponent,
    ExistingMemberRecentTransactionsListComponent,
    PrimeTemplate,
    PanelModule,
    FaIconComponent,
    FormatPhonePipe,
    NgIf,
    AvatarModule,
  ],
  templateUrl: './existing-transaction-consumer-details.component.html',
  styleUrl: './existing-transaction-consumer-details.component.scss',
})
export class ExistingTransactionConsumerDetailsComponent {
  private readonly store = inject(Store);

  consumer = input<Consumer | null>();

  protected name = computed(() => {
    const consumer = this.consumer();
    return isNotNullOrUndefined(consumer)
      ? `${this.consumer()?.NameFirst ?? 'VALUED'} ${this.consumer()?.NameLast ?? 'CUSTOMER'}`
      : 'Customer';
  });

  protected avatarInitials = computed(() => {
    const consumer = this.consumer();
    return isNotNullOrUndefined(consumer)
      ? `${this.consumer()?.NameFirst?.substring(0, 1)}${this.consumer()?.NameLast?.substring(0, 1)}`
      : 'C';
  });

  protected icons = FONT_AWESOME_ICONS;
  protected faSquareEnvelope = FA_ICON.SQUARE_ENVELOPE;
  protected faSquarePhone = FA_ICON.SQUARE_PHONE;
  protected faFileInvoiceDollar = FA_ICON.FILE_INVOICE_DOLLAR;

  protected navigateToConsumer(consumer: Consumer) {
    const path = `/members/${consumer.ConsumerId}`;
    this.store.dispatch(
      RouterActions.navigateByUrlWithLocation({
        payload: { path },
      })
    );
  }
}
