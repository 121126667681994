import { Component, inject, OnInit } from '@angular/core';
import { EpTableComponent, EPTableConfig, FONT_AWESOME_ICONS } from '@ep/shared';
import { BatchTableDisplay } from '../../models/BatchTableDisplay';
import { AsyncPipe } from '@angular/common';
import { ReportsApiService } from '../../services';
import { map, Observable, of, switchMap } from 'rxjs';
import { Router } from '@angular/router';
import { ApplicationProfileService } from '@app/@shared/services/application-profile.service';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { Batch } from '@app/resources/services';

@Component({
  selector: 'app-existing-batch-report',
  standalone: true,
  imports: [EpTableComponent, AsyncPipe, FaIconComponent],
  template: `
    <div class="p-4">
      <div class="breadcrumb-item pb-5">
        <fa-icon [icon]="icons['faFileExport']" size="lg" [fixedWidth]="true" class="text-gray-500" />
        <span (click)="navigateBack()">Reports </span>
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="p-icon"
          aria-hidden="true"
        >
          <path
            d="M4.38708 13C4.28408 13.0005 4.18203 12.9804 4.08691 12.9409C3.99178 12.9014 3.9055 12.8433 3.83313 12.7701C3.68634 12.6231 3.60388 12.4238 3.60388 12.2161C3.60388 12.0084 3.68634 11.8091 3.83313 11.6622L8.50507 6.99022L3.83313 2.31827C3.69467 2.16968 3.61928 1.97313 3.62287 1.77005C3.62645 1.56698 3.70872 1.37322 3.85234 1.22959C3.99596 1.08597 4.18972 1.00371 4.3928 1.00012C4.59588 0.996539 4.79242 1.07192 4.94102 1.21039L10.1669 6.43628C10.3137 6.58325 10.3962 6.78249 10.3962 6.99022C10.3962 7.19795 10.3137 7.39718 10.1669 7.54416L4.94102 12.7701C4.86865 12.8433 4.78237 12.9014 4.68724 12.9409C4.59212 12.9804 4.49007 13.0005 4.38708 13Z"
            fill="currentColor"
          ></path>
        </svg>
        <span>Batches </span>
      </div>

      <app-ep-table
        [config]="config"
        [data]="batches$ | async"
        data-cy="batches-table"
        (dateChange)="dateChange($event)"
      />
    </div>
  `,
  styleUrl: './existing-batch-report.component.scss',
})
export class ExistingBatchReportComponent implements OnInit {
  private readonly reportService = inject(ReportsApiService);
  private readonly router = inject(Router);
  private readonly applicationProfileService = inject(ApplicationProfileService);

  protected icons = FONT_AWESOME_ICONS;

  protected calendarFilter: Date | undefined;

  protected config: EPTableConfig<BatchTableDisplay> = {
    header: 'Batches',
    pagination: {
      rows: 10,
      showCurrentPageReport: true,
    },
    columns: [
      {
        header: 'Batch Id',
        field: 'MerchantBatchId',
        columnType: 'string',
        defaultValue: null,
      },
      {
        header: 'Batch Closed Date',
        field: 'ClosedDateTime',
        columnType: 'date',
        dateFormat: 'MM/dd/YY h:mm a',
        defaultValue: null,
      },
    ],
    style: {},
    calendarFilter: true,
    calendarDate: this.router.parseUrl(this.router.url).queryParams['date']
      ? new Date(`${this.router.parseUrl(this.router.url).queryParams['date'] + 'T00:00:00'}`)
      : new Date(),
    actions: {
      rowClick: (batch: BatchTableDisplay) => {
        this.navigateToBatchDetail(batch.BatchId, batch.ClosedDateTime);
      },
    },
  };

  protected batches$: Observable<Batch[]> = of();
  protected location$: Observable<number> = this.applicationProfileService.applicationProfile$.pipe(
    switchMap((appProfile) => {
      return of(appProfile.Location.LocationId);
    })
  );

  public ngOnInit(): void {
    const queryParams = this.router.parseUrl(this.router.url).queryParams;

    if (queryParams['date']) {
      this.batches$ = this.reportService
        .searchForBatchesByDate(new Date(`${queryParams['date']}T00:00:00`))
        .pipe(map((batches) => batches));
    } else {
      this.batches$ = this.reportService.searchForBatchesByDate(new Date()).pipe(map((batches) => batches));
    }

    this.handleSingleBatchNavigation();
  }

  private handleSingleBatchNavigation(): void {
    const queryParams = this.router.parseUrl(this.router.url).queryParams;
    if (queryParams['date']) {
      return;
    }

    this.batches$.subscribe((batches) => {
      if (batches.length === 1) {
        this.navigateToBatchDetail(batches[0].BatchId, batches[0].ClosedDateTime);
      }
    });
  }

  private navigateToBatchDetail(batchId: number, closedDateTime?: Date | string | null): void {
    this.location$.subscribe((location) => {
      this.router.navigate([`${location}/batch-detail`, batchId], {
        queryParams: { date: closedDateTime?.toString().split('T')[0] },
      });
    });
  }

  public dateChange(date: Date): void {
    this.batches$ = this.reportService.searchForBatchesByDate(date).pipe(map((batches) => batches));

    // Clear the queryParams to allow the Batch Report detail to be loaded
    this.router.navigate([], {
      queryParams: { date: null },
      queryParamsHandling: 'merge',
    });

    this.calendarFilter = date;

    setTimeout(() => {
      this.handleSingleBatchNavigation();
    }, 100);
  }

  public navigateBack(): void {
    this.location$.subscribe((location) => {
      this.router.navigate([`${location}/reports`]);
    });
  }
}
