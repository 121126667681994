import { Component, inject, OnInit } from '@angular/core';
import { EpTableComponent, EPTableConfig, FONT_AWESOME_ICONS } from '@ep/shared';
import { AsyncPipe, DatePipe, NgTemplateOutlet } from '@angular/common';
import { ReportsApiService } from '../../services';
import { ActivatedRoute, Router } from '@angular/router';
import { SettlementReportItem } from '@app/resources/services';
import { map, Observable, of, switchMap, tap, catchError } from 'rxjs';
import { ApplicationProfileService } from '@app/@shared/services/application-profile.service';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { mapToBatchDetail } from '../../utils';

@Component({
  selector: 'app-existing-batch-report-detail',
  standalone: true,
  imports: [EpTableComponent, AsyncPipe, FaIconComponent, DatePipe, NgTemplateOutlet],
  template: `
    <ng-template #icon>
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="p-icon"
        aria-hidden="true"
      >
        <path
          d="M4.38708 13C4.28408 13.0005 4.18203 12.9804 4.08691 12.9409C3.99178 12.9014 3.9055 12.8433 3.83313 12.7701C3.68634 12.6231 3.60388 12.4238 3.60388 12.2161C3.60388 12.0084 3.68634 11.8091 3.83313 11.6622L8.50507 6.99022L3.83313 2.31827C3.69467 2.16968 3.61928 1.97313 3.62287 1.77005C3.62645 1.56698 3.70872 1.37322 3.85234 1.22959C3.99596 1.08597 4.18972 1.00371 4.3928 1.00012C4.59588 0.996539 4.79242 1.07192 4.94102 1.21039L10.1669 6.43628C10.3137 6.58325 10.3962 6.78249 10.3962 6.99022C10.3962 7.19795 10.3137 7.39718 10.1669 7.54416L4.94102 12.7701C4.86865 12.8433 4.78237 12.9014 4.68724 12.9409C4.59212 12.9804 4.49007 13.0005 4.38708 13Z"
          fill="currentColor"
        ></path>
      </svg>
    </ng-template>
    <div class="p-4">
      <div class="breadcrumb-item pb-5">
        <fa-icon [icon]="icons['faFileExport']" size="lg" [fixedWidth]="true" class="text-gray-500" />
        <span (click)="navigateBackToReports()">Reports </span>
        <ng-container *ngTemplateOutlet="icon" />
        <span (click)="navigateBack()">Batch Reports {{ displayBatchDate | date : 'yyyy-MM-dd' }} </span>
        <ng-container *ngTemplateOutlet="icon" />
        <span>Batch Id {{ displayBatchId }}</span>
      </div>
      <app-ep-table
        [config]="config"
        [data]="batchDetail$ | async"
        (dateChange)="dateChange($event)"
        data-cy="batches-detail-table"
      />
    </div>
  `,
  styleUrl: './existing-batch-report-detail.component.scss',
})
export class ExistingBatchReportDetailComponent implements OnInit {
  private readonly route = inject(ActivatedRoute);
  private readonly router = inject(Router);
  private readonly reportService = inject(ReportsApiService);
  private readonly applicationProfileService = inject(ApplicationProfileService);

  icons = FONT_AWESOME_ICONS;

  protected batchId: string | undefined = this.route.snapshot.url.map((segment) => segment.path).pop();
  protected displayBatchId: number | undefined;
  protected displayBatchDate: Date | null = null;

  protected batchDetail$: Observable<SettlementReportItem[]> = of([]);

  protected config: EPTableConfig<SettlementReportItem> = {
    header: 'Batch Details',
    columns: [
      {
        header: 'Label',
        field: 'Label',
        columnType: 'string',
        defaultValue: null,
      },
      {
        header: 'Quantity',
        field: 'Count',
        columnType: 'string',
        defaultValue: '0',
      },
      {
        header: 'Amount',
        field: 'Amount',
        columnType: 'string',
        defaultValue: null,
      },
    ],
    calendarFilter: true,
    calendarDate: this.router.parseUrl(this.router.url).queryParams['date']
      ? new Date(`${this.router.parseUrl(this.router.url).queryParams['date'] + 'T00:00:00'}`)
      : new Date(),
    style: {},
    globalFilterFields: ['Label'],
  };

  public ngOnInit(): void {
    this.batchDetail$ = this.reportService.searchForBatchesById(Number(this.batchId)).pipe(
      tap((batch) => {
        this.displayBatchId = batch.batchId;
        this.displayBatchDate = batch.closeDate;
      }),
      map(mapToBatchDetail)
    );
  }

  protected location$: Observable<number> = this.applicationProfileService.applicationProfile$.pipe(
    switchMap((appProfile) => {
      return of(appProfile.Location.LocationId);
    })
  );

  public navigateBack(): void {
    this.location$.subscribe((location) => {
      const formattedDate = this.displayBatchDate
        ? new Date(this.displayBatchDate + 'Z').toISOString().split('T')[0]
        : undefined;

      this.router.navigate([`${location}/reports/BatchReport`], { queryParams: { date: formattedDate } });
    });
  }

  public navigateBackToReports(): void {
    this.location$.subscribe((location) => {
      this.router.navigate([`${location}/reports`]);
    });
  }

  public dateChange(date: Date): void {
    this.batchDetail$ = this.reportService.searchForBatchesByDate(date).pipe(
      switchMap((batches) => {
        if (batches.length === 0) {
          return of([]);
        }

        if (batches.length === 1) {
          return this.reportService.searchForBatchesById(Number(batches[0].BatchId)).pipe(
            tap((batch) => {
              this.displayBatchId = batch.batchId;
              this.displayBatchDate = batch.closeDate;
            }),
            map(mapToBatchDetail)
          );
        }

        // Multiple batches found, navigate to batch list
        this.displayBatchDate = batches[0].ClosedDateTime;
        this.navigateBack();
        return of([]);
      }),
      catchError((error) => {
        console.error('Error fetching batch data:', error);
        return of([]);
      })
    );
  }
}
