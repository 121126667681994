import { inject, Injectable } from '@angular/core';
import { ReportService, BatchService } from '@app/resources/services';
import { map } from 'rxjs/operators';
import { mapToBatches, mapToDailySalesReports, mapToReports } from '@app/areas/reports/utils/mappers';

@Injectable()
export class ReportsApiService {
  private readonly reportService = inject(ReportService);
  private readonly reportBatch = inject(BatchService);

  getReports() {
    return this.reportService.findAll().pipe(map(mapToReports));
  }

  searchForReportData(searchDate: Date) {
    return this.reportService.findDailySales(searchDate).pipe(map(mapToDailySalesReports));
  }

  getBatches() {
    return this.reportBatch.findAll().pipe(map(mapToBatches));
  }

  searchForBatchesById(batchId: number) {
    return this.reportBatch.findDailyBatchesById(batchId).pipe();
  }

  searchForBatchesByDate(searchDate: Date) {
    return this.reportBatch.findDailyBatchesByDate(searchDate).pipe(map(mapToBatches));
  }
}
