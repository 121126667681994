// Angular Core Imports
import { Component, computed, input, OnInit, Signal } from '@angular/core';
import { CurrencyPipe, DatePipe } from '@angular/common';

// Third-Party Imports
import { AvatarModule } from 'primeng/avatar';
import { PanelModule } from 'primeng/panel';

// Application-Specific Imports
import { Consumer, OrderPayment } from '@app/resources/services';

@Component({
  selector: 'app-existing-member-information',
  standalone: true,
  imports: [AvatarModule, DatePipe, CurrencyPipe, PanelModule],
  templateUrl: './existing-member-information.component.html',
  styleUrl: './existing-member-information.component.scss',
})
export class ExistingMemberInformationComponent implements OnInit {
  member = input.required<Consumer>();

  public amountTotal = 0;
  public averageSpend = 0;
  public rewardBalance = 0;
  public totalOrders = 0;
  public assignDateTime = 0;

  protected recentOrderPayment = computed(() => {
    const orderPayments = [...this.member().OrderPayments!].sort((op1, op2) => {
      const date1 = new Date(op1.StartDateTime);
      const date2 = new Date(op2.StartDateTime);
      return date2.getTime() - date1.getTime();
    });
    return orderPayments.length > 0 ? orderPayments[0] : null;
  });

  ngOnInit(): void {
    this.totalOrders = this.member().OrderPayments?.length ?? 0;
    this.rewardBalance = this.member().LoyaltyProgram?.RewardBalance ?? 0;
    this.assignDateTime = this.member().ConsumerRelationship?.AssignDateTime ?? 0;

    this.member().OrderPayments?.forEach((payment) => {
      this.amountTotal += payment.AmountTotal || 0;
    });

    if (this.member().OrderPayments && this.member().OrderPayments!.length > 0) {
      this.averageSpend = this.amountTotal / this.member()!.OrderPayments!.length;
    } else {
      this.averageSpend = 0;
    }
  }
}
