import { Order, OrderResponse, OrderReview, OrderReviews, OrdersResponse } from '@app/resources/services';
import { EnrollmentTypes, OrderStatusTypes } from '@shared/enums';
import { TransactionTableDisplay } from '@app/areas/transactions/models';
import { mapToConsumerOrderPayments } from '@app/areas/members/utils';

export const mapToOrders = (data: OrdersResponse): Order[] => {
  return data.orders.$values.map(mapToOrder);
};

export const mapToOrder = (data: OrderResponse): Order => {
  return {
    OrderId: data.orderId,
    OrderGuid: data.orderGuid,
    EmployeeId: data.employeeId,
    StatusType: data.statusType,
    AmountTotal: data.amountTotal,
    StartDateTime: data.startDateTime,
    EnrollmentType: data.enrollmentType,
    ReviewIds: data.reviewIds.$values,
    Reviews:
      data.orderReviews && data.orderReviews.$values
        ? data.orderReviews.$values.map((review: OrderReviews) => mapToOrderReview(review))
        : [],
    OrderPayments: mapToConsumerOrderPayments(data.orderPayments),
  };
};

export const mapToTransactionTableDisplays = (
  data: Order[] | null,
  utcOffsetHour: number
): TransactionTableDisplay[] | null => {
  return data ? data.map((order) => mapToTransactionTableDisplay(order, utcOffsetHour)) : null;
};

export const mapToTransactionTableDisplay = (data: Order, utcOffsetHour: number): TransactionTableDisplay => {
  const isMember: boolean = data.OrderPayments.some(
    (orderPayment) => orderPayment.ConsumerId != null && orderPayment.ConsumerId !== 0
  );

  const startDateTime = new Date(data.StartDateTime);
  startDateTime.setHours(startDateTime.getHours() + utcOffsetHour);

  return {
    OrderId: data.OrderId,
    Employee: data.Employee ? `${data.Employee.NameFirst} ${data.Employee.NameLast}` : '',
    StatusType: mapOrderStatusToValue(data),
    EnrollmentType: mapOrderEnrollmentToValue(data),
    AmountTotal: data.AmountTotal,
    StartDateTime: getOrderDateUtc(data.StartDateTime, utcOffsetHour),
    Score: data.Reviews && data.Reviews.length > 0 ? data.Reviews[0].Score : undefined,
    IsMember: isMember,
  };
};

function hasFlag(value: number, flag: number) {
  return (value & flag) === flag;
}

export const mapOrderStatusToValue = (order: Order) => {
  if (hasFlag(order.StatusType, OrderStatusTypes.Voided)) return 'Voided';
  if (hasFlag(order.StatusType, OrderStatusTypes.Canceled)) return 'Cancelled';
  if (hasFlag(order.StatusType, OrderStatusTypes.Completed)) return 'Completed';
  if (hasFlag(order.StatusType, OrderStatusTypes.Open)) return 'Open';
  return 'Pending';
};

export const getOrderStatusSeverity = (order: Order) => {
  if (hasFlag(order.StatusType, OrderStatusTypes.Voided)) return 'warning';
  if (hasFlag(order.StatusType, OrderStatusTypes.Canceled)) return 'danger';
  if (hasFlag(order.StatusType, OrderStatusTypes.Completed)) return 'success';
  if (hasFlag(order.StatusType, OrderStatusTypes.Open)) return 'info';
  return 'info';
};

export const mapOrderEnrollmentToValue = (order: Order) => {
  if (hasFlag(order.EnrollmentType, EnrollmentTypes.CanceledEnrollment)) return 'Cancelled';
  if (hasFlag(order.EnrollmentType, EnrollmentTypes.LoyaltyProgramDeclined)) return 'Declined';
  if (hasFlag(order.EnrollmentType, EnrollmentTypes.LoyaltyProgramExisting)) return 'Existing';
  if (hasFlag(order.EnrollmentType, EnrollmentTypes.LoyaltyProgramEnrolled)) return 'Enrolled';
  return 'N/A';
};

export const getOrderEnrollmentSeverity = (order: Order) => {
  if (hasFlag(order.EnrollmentType, EnrollmentTypes.CanceledEnrollment)) return 'danger';
  if (hasFlag(order.EnrollmentType, EnrollmentTypes.LoyaltyProgramDeclined)) return 'warning';
  if (hasFlag(order.EnrollmentType, EnrollmentTypes.LoyaltyProgramExisting)) return 'info';
  if (hasFlag(order.EnrollmentType, EnrollmentTypes.LoyaltyProgramEnrolled)) return 'success';
  return 'warning';
};

const mapToOrderReview = (review: OrderReviews): OrderReview => {
  return {
    OrderReviewId: review.orderReviewId,
    ConsumerId: review.consumerId,
    Consumer: null,
    Score: review.score,
    CreatedDateTime: review.createdDateTime,
    UpdateDateTime: review.updateDateTime,
    AmountTotal: 0,
    QuestionIds: [],
    Questions: [],
  };
};

export const getOrderDateUtc = (originalDate: Date | string, utcOffSetHour: number): string => {
  const startDateTime = new Date(originalDate);
  startDateTime.setHours(startDateTime.getHours() + utcOffSetHour);
  return startDateTime.toISOString();
};
