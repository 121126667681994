// Angular Core Imports
import { Component, DestroyRef, inject, input, OnInit, signal } from '@angular/core';
import { AsyncPipe, CurrencyPipe, DatePipe, NgClass, NgForOf, NgIf } from '@angular/common';

// Third-Party Imports
import { DataViewModule } from 'primeng/dataview';
import { Button } from 'primeng/button';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { Store } from '@ngrx/store';
import { PanelModule } from 'primeng/panel';

// Shared and Application-Specific Imports
import { Consumer, Order, OrderPayment } from '@app/resources/services';
import { TruncateGuidPipe } from '@shared/pipes';
import { RouterActions } from '@app/resources/ngrx/actions';

import { fromEvent, interval, of, throwError } from 'rxjs';
import { catchError, mergeMap, retryWhen } from 'rxjs/operators';
import { TransactionApiService } from '@app/areas/transactions/services';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ApplicationProfileService } from '@app/@shared/services/application-profile.service';
import { ApplicationProfile } from '@app/@shared/models';
import { getOrderDateUtc } from '@app/areas/transactions/utils/mappers';

@Component({
  selector: 'app-existing-member-recent-transactions-list',
  standalone: true,
  imports: [
    DataViewModule,
    NgForOf,
    CurrencyPipe,
    FaIconComponent,
    NgIf,
    Button,
    NgClass,
    TruncateGuidPipe,
    DatePipe,
    AsyncPipe,
    PanelModule,
  ],
  templateUrl: './existing-member-recent-transactions-list.component.html',
  styleUrl: './existing-member-recent-transactions-list.component.scss',
})
export class ExistingMemberRecentTransactionsListComponent implements OnInit {
  private readonly store = inject(Store);
  private readonly transactionsApiService = inject(TransactionApiService);
  private readonly destroyedRef: DestroyRef = inject(DestroyRef);
  private readonly applicationProfileService = inject(ApplicationProfileService);

  public member = input.required<Consumer>();
  public orderPaymentsCount = input.required<number>();

  public lastPageClicked: number = 1;
  public isUserOnline?: boolean = true;

  protected orderPayments = signal<Order[]>([]);
  protected totalRecords: number = 0;
  protected isLoading: boolean = true;

  protected locationUtcOffsetHour: number = 0;

  get totalRecordsArray(): number[] {
    const numPlaceholders = Math.min(this.totalRecords, 4);
    return Array.from({ length: numPlaceholders }, (_, i) => i);
  }

  public ngOnInit(): void {
    this.fetchOrdersReviewsData(1);
    this.totalRecords = this.orderPaymentsCount();

    this.applicationProfileService.applicationProfile$.pipe().subscribe((applicationProfile: ApplicationProfile) => {
      this.locationUtcOffsetHour = applicationProfile.Location.UtcOffsetHour;
    });
  }

  public fetchOrdersReviewsData(pageNumber: number): void {
    if (!navigator.onLine) {
      this.isUserOnline = false;

      const sub = interval(3000).subscribe(() => {
        if (navigator.onLine) {
          sub.unsubscribe();
        }
      });
      return;
    }

    this.isLoading = true;

    this.transactionsApiService
      .getTransactionsWithReviews(pageNumber, 4, this.member().ConsumerId)
      .pipe(
        catchError(() => of()),
        takeUntilDestroyed(this.destroyedRef)
      )
      .subscribe(({ orders, totalTransactions }) => {
        if (orders.length === 0 && totalTransactions === 0) {
          this.isUserOnline = false;
          return;
        }

        this.totalRecords = totalTransactions;
        this.orderPayments.set(orders);
        this.isLoading = false;
        this.isUserOnline = true;
      });
  }

  protected getReviewBadge(order: Order): { value: string; cssClass: string } {
    const isMember: boolean = this.member().ConsumerRelationship?.AssignDateTime ? true : false;

    var score = null;
    if (order.Reviews && order.Reviews.length > 0 && order.Reviews[0]) {
      score = order.Reviews[0].Score;
    }

    if (score === 5) {
      return { value: 'Great', cssClass: 'member-transactions-great' };
    } else if (score === 0) {
      return { value: 'Poor', cssClass: 'member-transactions-poor' };
    } else if (score === undefined) {
      return { value: '', cssClass: 'rating-skeleton skeleton' };
    } else if (score === null && isMember) {
      return { value: 'Not Answered', cssClass: 'member-transactions-not-answered' };
    } else {
      return { value: '', cssClass: '' };
    }
  }

  protected navigateToOrder(payment: OrderPayment) {
    this.store.dispatch(
      RouterActions.navigateByUrlWithLocation({
        payload: {
          path: `transactions/${payment.OrderId}`,
        },
      })
    );
  }

  protected pageChangeEvent(event: { first: number; rows: number }): void {
    const pageNumber = event.first === 0 ? 1 : Math.floor(event.first / 4) + 1;
    this.lastPageClicked = pageNumber;
    this.fetchOrdersReviewsData(pageNumber);
  }

  protected getTransactionDateUtc(originalDate: Date): string {
    return getOrderDateUtc(originalDate, this.locationUtcOffsetHour);
  }
}
